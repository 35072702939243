import React from 'react';

const JobListings = () => {
    // Sample job data (replace with API fetch later)
    const jobs = [
        {
            id: 1,
            title: 'Software Engineer',
            location: 'Remote',
            description: 'Develop and maintain web applications.',
            salary: '$80,000 - $120,000',
            requirements: ['3+ years of experience', 'Proficient in React and Node.js'],
        },
        {
            id: 2,
            title: 'Product Manager',
            location: 'New York, NY',
            description: 'Lead product development and strategy.',
            salary: '$100,000 - $150,000',
            requirements: ['5+ years in product management', 'Strong leadership skills'],
        },
        {
            id: 3,
            title: 'UX Designer',
            location: 'San Francisco, CA',
            description: 'Design user-friendly interfaces and experiences.',
            salary: '$70,000 - $110,000',
            requirements: ['Portfolio of UX/UI designs', 'Experience with Figma or Sketch'],
        },
    ];

    return (
        <div className="container mx-auto py-10">
            <h2 className="text-3xl font-bold text-gray-800 mb-6">Job Listings</h2>
            {jobs.length === 0 ? (
                <p className="text-gray-600">No jobs available at the moment.</p>
            ) : (
                <ul className="space-y-6">
                    {jobs.map((job) => (
                        <li key={job.id} className="border rounded-lg p-6 shadow hover:shadow-lg transition">
                            <h3 className="text-2xl font-semibold text-gray-800">{job.title}</h3>
                            <p className="text-gray-600 mb-2">{job.location}</p>
                            <p className="mb-4">{job.description}</p>
                            <div className="mb-4">
                                <p className="font-bold text-gray-700">Salary:</p>
                                <p>{job.salary}</p>
                            </div>
                            <div className="mb-4">
                                <p className="font-bold text-gray-700">Requirements:</p>
                                <ul className="list-disc list-inside">
                                    {job.requirements.map((req, index) => (
                                        <li key={index}>{req}</li>
                                    ))}
                                </ul>
                            </div>
                            <a
                                href={`/job/${job.id}`}
                                className="bg-blue-700 text-white py-2 px-4 rounded hover:bg-blue-800 transition inline-block"
                            >
                                View Details
                            </a>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default JobListings;
