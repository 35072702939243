import React from 'react';
import {BrowserRouter as Router, Link, Route, Routes} from 'react-router-dom';
import JobSeekerForm from './components/JobSeekerForm';
import EmployerForm from './components/EmployerForm';
import Register from './components/Register';
import Login from './components/Login';



import HomePage from "./pages/HomePage";
import JobListings from "./pages/JobListings"; // Ensure Tailwind CSS is included here
import JobDetails from './pages/JobDetails'; // Import JobDetails component
import EmployerDashboard from "./pages/EmployerDashboard";

import './index.css';

const App = () => {
    return (
        <Router>
            <div className="min-h-screen bg-gray-50">
                {/* Navigation */}
                <nav className="bg-blue-700 p-4 shadow">
                    <div className="container mx-auto flex justify-between">
                        <Link to="/" className="text-white text-lg font-bold">United Recruiting Service</Link>
                        <div>
                            <Link to="/" className="text-white mx-4">Home</Link>
                            <Link to="/jobs" className="text-white mx-4">Job Listings</Link>
                            <Link to="/dashboard" className="text-white mx-4">Employer Dashboard</Link>
                        </div>
                    </div>
                </nav>

                {/* Routes */}
                <Routes>
                    <Route path="/" element={<HomePage/>}/> {/* Home Page Route */}
                    <Route path="/job-seeker-form" element={<JobSeekerForm/>}/>
                    <Route path="/employer-form" element={<EmployerForm/>}/>
                    <Route path="/jobs" element={<JobListings/>}/>
                    <Route path="/job/:jobId" element={<JobDetails/>}/> {/* Job Details route */}
                    <Route path="/dashboard" element={<EmployerDashboard />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/login" element={<Login />} /> </Routes>
            </div>
        </Router>
    );
};

export default App;
