// EmployerDashboard.js
import React from 'react';

const EmployerDashboard = () => {
    return (
        <div className="container mx-auto p-4">
            <h1 className="text-3xl font-bold mb-4">Employer Dashboard</h1>

            {/* Overview Section */}
            <div className="bg-white shadow-md rounded-lg p-4 mb-6">
                <h2 className="text-xl font-semibold">Dashboard Overview</h2>
                <p>Welcome back, [Employer Name]!</p>
                <div className="flex justify-between mt-4">
                    <div className="text-center">
                        <h3 className="text-2xl">5</h3>
                        <p>Active Job Listings</p>
                    </div>
                    <div className="text-center">
                        <h3 className="text-2xl">12</h3>
                        <p>Applications Received</p>
                    </div>
                </div>
            </div>

            {/* Manage Job Listings Section */}
            <div className="bg-white shadow-md rounded-lg p-4 mb-6">
                <h2 className="text-xl font-semibold">Manage Job Listings</h2>
                <button className="bg-blue-600 text-white px-4 py-2 rounded mb-4">
                    Create New Job Listing
                </button>
                <table className="min-w-full border-collapse border border-gray-300">
                    <thead>
                    <tr>
                        <th className="border border-gray-300 p-2">Job Title</th>
                        <th className="border border-gray-300 p-2">Status</th>
                        <th className="border border-gray-300 p-2">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {/* Sample Data */}
                    <tr>
                        <td className="border border-gray-300 p-2">Software Engineer</td>
                        <td className="border border-gray-300 p-2">Active</td>
                        <td className="border border-gray-300 p-2">
                            <button className="text-blue-600">Edit</button>
                            <button className="text-red-600 ml-2">Delete</button>
                        </td>
                    </tr>
                    {/* More listings can be added here */}
                    </tbody>
                </table>
            </div>

            {/* Applications Overview Section */}
            <div className="bg-white shadow-md rounded-lg p-4">
                <h2 className="text-xl font-semibold">Applications Overview</h2>
                <table className="min-w-full border-collapse border border-gray-300">
                    <thead>
                    <tr>
                        <th className="border border-gray-300 p-2">Applicant Name</th>
                        <th className="border border-gray-300 p-2">Job Title</th>
                        <th className="border border-gray-300 p-2">Status</th>
                        <th className="border border-gray-300 p-2">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {/* Sample Data */}
                    <tr>
                        <td className="border border-gray-300 p-2">John Doe</td>
                        <td className="border border-gray-300 p-2">Software Engineer</td>
                        <td className="border border-gray-300 p-2">Under Review</td>
                        <td className="border border-gray-300 p-2">
                            <button className="text-blue-600">View Details</button>
                        </td>
                    </tr>
                    {/* More applications can be added here */}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default EmployerDashboard;
