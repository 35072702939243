// HomePage.js
import React from 'react';

const HomePage = () => {
    return (
        <div
            className="bg-cover bg-center h-screen"
            style={{ backgroundImage: `url('./images/office.jpg')` }}
        >
            {/* Overlay */}
            <div className="flex items-center justify-center h-full bg-black bg-opacity-50">
                <div className="text-white text-center px-4">
                    <h1 className="text-5xl font-bold mb-4">Welcome to URS</h1>
                    <div className="bg-white bg-opacity-90 text-gray-800 rounded shadow-lg p-8 inline-block">
                        <h2 className="text-4xl font-bold mb-4">Find Your Dream Job</h2>
                        <p className="text-lg mb-6">
                            Join top companies and start your journey to success.
                        </p>
                        <div className="space-x-4">
                            <a
                                href="/job-seeker-form"
                                className="bg-blue-600 text-white px-6 py-2 rounded hover:bg-blue-700 transition"
                            >
                                I’m a Job Seeker
                            </a>
                            <a
                                href="/employer-form"
                                className="bg-yellow-600 text-white px-6 py-2 rounded hover:bg-yellow-700 transition"
                            >
                                I’m an Employer
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            {/* Job Browsing Section */}
            <section className="bg-gray-100 py-12">
                <div className="container mx-auto text-center">
                    <h2 className="text-3xl font-bold text-gray-800 mb-4">Browse Job Listings</h2>
                    <p className="text-lg text-gray-600 mb-6">
                        Explore our available job opportunities below.
                    </p>
                    <a
                        href="/jobs"
                        className="bg-blue-700 text-white py-3 px-8 rounded hover:bg-blue-800 transition"
                    >
                        Browse Jobs
                    </a>
                </div>
            </section>
        </div>
    );
};

export default HomePage;
