// JobDetails.js
import React from 'react';
import { useParams } from 'react-router-dom';

const JobDetails = () => {
    const { jobId } = useParams();

    // Replace with real data fetching logic based on jobId
    const job = {
        id: jobId,
        title: 'Software Engineer',
        company: 'Tech Company',
        location: 'Remote',
        description: 'Develop and maintain web applications.',
        requirements: ['JavaScript', 'React', 'Node.js'],
    };

    return (
        <div className="container mx-auto py-10">
            <h2 className="text-3xl font-bold text-gray-800 mb-4">{job.title}</h2>
            <p className="text-gray-600">{job.company} - {job.location}</p>
            <p className="mt-2">{job.description}</p>
            <h3 className="mt-4 font-semibold">Requirements:</h3>
            <ul className="list-disc pl-5">
                {job.requirements.map((req, index) => (
                    <li key={index}>{req}</li>
                ))}
            </ul>
            <a href="/jobs" className="text-blue-700 mt-4 inline-block">
                Back to Job Listings
            </a>
        </div>
    );
};

export default JobDetails;
