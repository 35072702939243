import React, { useState } from 'react';

const EmployerForm = () => {
    const [formData, setFormData] = useState({
        company: '',
        email: '',
        jobDetails: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Logic to handle form submission
        console.log('Employer Info:', formData);
    };

    return (
        <div className="container mx-auto p-10">
            <h2 className="text-2xl font-bold mb-4">Employer Form</h2>
            <form onSubmit={handleSubmit} className="bg-white shadow-md rounded p-6">
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">Company Name</label>
                    <input
                        type="text"
                        name="company"
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">Email</label>
                    <input
                        type="email"
                        name="email"
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">Job Details</label>
                    <textarea
                        name="jobDetails"
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
                    />
                </div>
                <button type="submit" className="bg-green-500 text-white py-2 px-4 rounded">
                    Submit
                </button>
            </form>
        </div>
    );
};

export default EmployerForm;
